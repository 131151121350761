const handleConnectionConfirmed = (sendHandler) => {
  const messagePayload = {
    msg: 'get_accounts_list',
    other: {},
  };

  sendHandler('Accounts list request', messagePayload);
};

// Example of the process handler
export const processMessageHandler = (origin, data, actions, sendHandler) => {
  const {
    payload: { msg, other },
  } = data;

  switch (msg) {
    case 'connection_confirmed': {
      return handleConnectionConfirmed(sendHandler);
    }
    case 'accounts_list': {
      const { accounts } = other;
      const { setAccounts } = actions;
      return setAccounts(accounts);
    }
    case 'tx_processed_success': {
      const { response } = other;
      const { setTxProcessResponse } = actions;

      // setTimeout(() => {
      //   return setTxProcessResponse(response);
      // }, 5000);
      // return 1;
      return setTxProcessResponse(response);
    }
    case 'tx_processed_error_handled': {
      const { error } = other;
      const { setTxProcessError } = actions;

      return setTxProcessError(error);
    }
    case 'tx_processed_error_unhandled': {
      const { error } = other;
      const { setTxProcessError } = actions;

      return setTxProcessError(error);
    }
    default:
      return {};
  }
};
