/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const AccountsSelect = (props) => {
  const { accounts, selectedAccountHandler, accountListClassName = '' } = props;

  const dropDownNode = useRef();

  const [isOpened, setIsOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select an account');

  const { OLT: myAccounts = [] } = accounts;

  const handleDropdownAutoClosing = (e) => {
    document.removeEventListener('mousedown', handleDropdownAutoClosing);

    if (dropDownNode.current.contains(e.target)) {
      // clicked inside the dropdown. doing nothing. listener was removed
      return;
    }

    // Closing the dropdown
    setIsOpened(false);
  };

  const handleDropdownClick = (e) => {
    setIsOpened(!isOpened);
    document.addEventListener('mousedown', handleDropdownAutoClosing);
  };

  const handleSelectAccountClick = (account) => {
    const { accountName } = account;
    setSelectedOption(accountName);
    document.removeEventListener('mousedown', handleDropdownAutoClosing);
    setIsOpened(!isOpened);
    selectedAccountHandler(account);
  };

  return (
    <div
      ref={dropDownNode}
      className={`dropdown proposals-accounts-dropdown ${
        isOpened ? 'is-active' : ''
      } `}
    >
      <div className="dropdown-trigger ">
        <button
          type="button"
          className="button dropdown-select-button proposals-accounts-select proposals-select"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={handleDropdownClick}
        >
          <span className={accountListClassName}>{selectedOption}</span>
          <span className="icon is-small">
            <i
              className="fas fa-angle-down has-text-brand-dark"
              aria-hidden="true"
            />
          </span>
        </button>
      </div>
      <div className="dropdown-menu accounts-dropdown-menu" role="menu">
        <div className="dropdown-content">
          {myAccounts.map((account) => {
            const { address, accountName } = account;
            return (
              <a
                key={address}
                onClick={() => handleSelectAccountClick(account)}
                className="dropdown-item"
              >
                {accountName}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

AccountsSelect.propTypes = {
  accounts: PropTypes.object,
  selectedAccountHandler: PropTypes.func,
  accountListClassName: PropTypes.string,
};

export default AccountsSelect;
