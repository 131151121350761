import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CreateProposal from './CreateProposal';

import {
  getCreateProposalTx,
  loadProposalsTypes,
  loadProposalsInfo,
  setTxProcessStart,
  setTxProcessError,
  setTxProcessResponse,
  resetTxProcess,
  setAccounts,
} from '../../../../store/actions/proposalsActions';

import {
  selectProposalTypes,
  selectProposalOptions,
  selectTxFeeEstimation,
  selectTxRawData,
  selectTxProposalId,
  selectTxProcessResponse,
  selectTxProcessError,
  getAccounts,
} from '../../../../store/selectors/proposalsSelectors';

import withMessaging from '../../../common/withMessaging';

export default connect(
  (state) => ({
    accounts: getAccounts(state),
    proposalsTypes: selectProposalTypes(state),
    proposalsOptions: selectProposalOptions(state),
    feeEstimation: selectTxFeeEstimation(state),
    rawTx: selectTxRawData(state),
    proposalId: selectTxProposalId(state),
    txProcessResponse: selectTxProcessResponse(state),
    txProcessError: selectTxProcessError(state),
    isTxProcessLoading: state.proposals.isTxProcessLoading,
  }),
  (dispatch) => ({
    actions: {
      ...bindActionCreators(
        {
          setAccounts,
          getCreateProposalTx,
          loadProposalsTypes,
          loadProposalsInfo,
          setTxProcessStart,
          setTxProcessError,
          setTxProcessResponse,
          resetTxProcess,
        },
        dispatch
      ),
    },
  })
)(withMessaging(CreateProposal));
