import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import styles from './loader.module.scss';
import logo from '../../../images/logo-small.svg';

const Loader = ({ icon, message, className }) => (
  <div className={styles.loader}>
    <img
      src={icon || logo}
      alt={message || ''}
      className={styles.spinner}
    />
    <p>{message}</p>
  </div>
);

Loader.displayName = 'Loader';
Loader.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
};

export default Loader;
