/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { requestConfig } from '@oneledger/ons-sdk';

import { useHistory } from 'react-router-dom';

const renderDetailsAction = (row, handler, history) => {
  const { id } = row;
  return (
    <li>
      <a
        onClick={() => handler(history, id)}
        className="has-text-left proposals-action"
      >
        Details
      </a>
    </li>
  );
};

const renderFundAction = ({ id, statusForUICode }, handler, history) => {
  if (statusForUICode === requestConfig.ProposalUIStatus.FundingInProgress) {
    return (
      <li>
        <a
          onClick={() => handler(history, id)}
          className="has-text-left proposals-action"
        >
          Fund
        </a>
      </li>
    );
  }
};

const renderWithdrawalAction = (proposal, menuItemHandler, myHandler) => {
  const { statusForUICode, fundsRaised, isMine } = proposal;
  const [fundsValue] = fundsRaised.split(' ');

  const toRender =
    isMine &&
    (statusForUICode === requestConfig.ProposalUIStatus.FundingFailed ||
      statusForUICode === requestConfig.ProposalUIStatus.Cancelled) &&
    fundsValue > 0;

  if (toRender) {
    return (
      <li>
        <a
          onClick={() => menuItemHandler(proposal, myHandler)}
          className="has-text-left proposals-action"
        >
          Withdraw
        </a>
      </li>
    );
  }
};

const renderCancelAction = (proposal, menuItemHandler, myHandler) => {
  const { statusForUICode, isMine } = proposal;

  const toRender =
    isMine &&
    statusForUICode === requestConfig.ProposalUIStatus.FundingInProgress;

  if (toRender) {
    return (
      <li>
        <a
          onClick={() => menuItemHandler(proposal, myHandler)}
          className="has-text-left proposals-action"
        >
          Cancel
        </a>
      </li>
    );
  }
};

const ProposalActionsMenu = (props) => {
  const { row, actions, myHandler } = props;

  const history = useHistory();

  const {
    handleActionsMenuDetailsClick,
    handleActionsMenuFundClick,
    handleActionsMenuWithdrawalClick,
    handleActionsMenuCancelClick,
  } = actions;

  return (
    <div className="actions-column actions-container show-overflow ">
      <div className="dropdown is-hoverable show-overflow">
        <div className="dropdown-trigger">
          <button
            type="button"
            className="button is-border-less is-outlined is-rounded has-text-brand-dark"
            aria-haspopup="true"
            aria-controls="dropdown-menu4"
          >
            <span className="icon ">
              <i className="fas fa-ellipsis-h" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
          <div className="dropdown-content proposals-actions">
            <div className="dropdown-item">
              <ul className="menu-list">
                {renderDetailsAction(
                  row,
                  handleActionsMenuDetailsClick,
                  history
                )}
                {renderFundAction(row, handleActionsMenuFundClick, history)}
                {renderWithdrawalAction(
                  row,
                  handleActionsMenuWithdrawalClick,
                  myHandler
                )}
                {renderCancelAction(
                  row,
                  handleActionsMenuCancelClick,
                  myHandler
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProposalActionsMenu.propTypes = {
  row: PropTypes.object,
  actions: PropTypes.object,
  myHandler: PropTypes.func,
};

export default ProposalActionsMenu;
