import { EXPLORER_URL } from '../constants/ons';

export const getTxLink = (txProcessResponse) => {
  try {
    const { txHash } = JSON.parse(txProcessResponse);
    const explorerTxUrl = `${EXPLORER_URL}/transaction/${txHash}`;
    return explorerTxUrl;
  } catch (err) {
    return txProcessResponse;
  }
};
