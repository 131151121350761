/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import styles from './createProposalStyle.scss';

import AccountsSelect from '../../../common/AccountsSelect';

import Loader from '../../../common/loader/loader';

import Mappings from '../../../../constants/mappings';

const renderProposalTypes = (proposalTypes) => {
  if (!proposalTypes) {
    return;
  }

  const { ProposalTypes } = Mappings;

  return proposalTypes.map((currentType) => {
    const typeToDisplay = ProposalTypes[currentType] || currentType;

    return (
      <option key={currentType} value={currentType}>
        {typeToDisplay}
      </option>
    );
  });
};

const CreateProposal = (props) => {
  const {
    accounts,
    proposalsTypes,
    proposalsOptions,
    feeEstimation,
    rawTx,
    txProcessError,
    txProcessResponse,
    actions,
    sendMessageHandler,
    isTxProcessLoading,
  } = props;

  const {
    getCreateProposalTx,
    loadProposalsTypes,
    loadProposalsInfo,
    setTxProcessStart,
    resetTxProcess,
  } = actions;

  const history = useHistory();

  const [proposalName, setProposalName] = useState('');
  const [proposalType, setProposalType] = useState('');
  const [proposalDescription, setProposalDescription] = useState('');

  const [proposalFundingGoalForUi, setProposalFundingGoalForUi] = useState('');
  const [proposalInitialFundingForUi, setProposalInitialFundingForUI] = useState('');
  const [proposalFundingDeadline, setProposalFundingDeadline] = useState('');

  const [proposer, setProposer] = useState('');
  const [initialFunds, setInitialFunds] = useState('');
  const [password, setPassword] = useState('');

  const [createProposalFee, setCreateProposalFee] = useState('');
  const [totalValue, setTotalValue] = useState('');

  const [selectedProposalsOption, setSelectedProposalsOption] = useState();

  const [keyPair, setKeyPair] = useState({});

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  const [isShowError, setIsShowError] = useState(true);

  const [isInitialFundInvalid, setIsInitialFundInvalid] = useState(false);

  // Set first proposal type as selected and request its proposal options - upon initial page load
  useEffect(() => {
    if (!proposalType && proposalsTypes.length > 0) {
      const [defaultProposalType] = proposalsTypes;

      setProposalType(defaultProposalType);
      loadProposalsInfo(defaultProposalType);
    }
  }, [proposalsTypes, proposalType, loadProposalsInfo]);

  // Load all proposal types and reset any transactions what is processed - upon initial page load
  useEffect(() => {
    loadProposalsTypes();
    resetTxProcess();
  }, [loadProposalsTypes, resetTxProcess]);

  // Update selected proposal option upon receiving proposalsOptions
  useEffect(() => {
    setSelectedProposalsOption(proposalsOptions);
  }, [proposalsOptions]);

  // Set feeEstimation and update totalValue (when is received together with rawTx)
  useEffect(() => {
    if (!feeEstimation) return;

    const { value: fee, currency } = feeEstimation;

    const newFunds = parseFloat(initialFunds);
    const numericFee = parseFloat(fee);

    const total = newFunds + numericFee;

    if (Number.isNaN(total) || !newFunds) {
      setTotalValue('');
      setCreateProposalFee('');

      return;
    }

    setTotalValue(`${total.toFixed(10)} ${currency}`);
    setCreateProposalFee(`${fee} ${currency}`);
  }, [feeEstimation, initialFunds]);

  // Validation of the initial fund field
  useEffect(() => {
    if (proposalFundingGoalForUi) {
      const [fundingGoalForUI] = proposalFundingGoalForUi.split(' ');
      const isValid = parseFloat(initialFunds) >= parseFloat(fundingGoalForUI);

      setIsInitialFundInvalid(isValid);
    }
  }, [proposalFundingGoalForUi, initialFunds]);

  // Update funding goal and deadline when proposal type changes
  useEffect(() => {
    if (!selectedProposalsOption) {
      return;
    }
    const { fundingDeadline, fundingGoalForUI, initialFundingForUI } = selectedProposalsOption;

    setProposalFundingGoalForUi(fundingGoalForUI);
    setProposalInitialFundingForUI(initialFundingForUI);
    setProposalFundingDeadline(fundingDeadline);
  }, [selectedProposalsOption]);

  // Listen to all form fields change and then trigger getCreateProposalTx with updated payload
  useEffect(() => {
    const payload = {
      proposalType,
      description: proposalDescription,
      headline: proposalName,
      proposer,
      initialFunding: `${initialFunds}`,
      password,
    };

    const payloadValues = Object.values(payload).filter(Boolean);

    const isPayloadCompleted =
      Object.keys(payload).length === payloadValues.length;

    if (isPayloadCompleted) {
      getCreateProposalTx(payload);
    } else {
      setTotalValue('');
      setCreateProposalFee('');
    }

    setIsCreateButtonDisabled(
      !isPayloadCompleted || !totalValue || isInitialFundInvalid
    );
  }, [
    proposalType,
    proposalDescription,
    proposalName,
    proposer,
    initialFunds,
    password,
    getCreateProposalTx,
    totalValue,
    isInitialFundInvalid,
  ]);

  const handleCancelCreateProposalClick = () => {
    history.push('/');
  };

  const handleConfirmCreateProposalClick = () => {
    const myMessagePayload = {
      msg: 'sign_tx',
      other: { txInfo: { rawTx, password, keyPair } },
    };

    setIsShowError(true);
    setTxProcessStart();
    sendMessageHandler('Transaction to sign', myMessagePayload);
  };

  const handleProposalTypeChange = (e) => {
    if (proposalType !== e.target.value) {
      setProposalType(e.target.value);
      loadProposalsInfo(e.target.value);
    }
  };

  const handleCurrentAccountChange = (account) => {
    const { address: selectedProposer, keyIndex, publicKey } = account;

    const newKeyPair = {
      keyType: 'OLT',
      keyIndex,
      publicKey,
      account: selectedProposer,
      // address: selectedProposer,
    };

    setKeyPair(newKeyPair);
    setProposer(selectedProposer);
  };

  const handleInitialFundChange = (e) => {
    const newValue = e.target.value;

    setInitialFunds(newValue);
  };

  if (txProcessResponse) {
    setTimeout(() => {
      history.replace('/');
    }, 500);
  }

  const handleNotificationCloseButtonClick = () => {
    setIsShowError(false);
  };

  const handleProposalNameChange = (e) => {
    setProposalName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setProposalDescription(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="create-proposal-main-container">
      <div className="create-proposal-container">
        <div className="proposal-form">
          {txProcessError && isShowError && (
            <article className="message is-danger is-small">
              <div className="message-header">
                <p>Error</p>
                <button
                  onClick={handleNotificationCloseButtonClick}
                  type="button"
                  className="delete is-small"
                  aria-label="delete"
                />
              </div>
              <div className="message-body">
                {txProcessError.message}.{txProcessError.detail}
              </div>
            </article>
          )}

          <p className="title">Create Proposal</p>

          {isTxProcessLoading ? (
            <div className={styles.loader}>
              <Loader message="Processing transaction..." />
            </div>
          ) : (
            <>
              <div className="field is-horizontal no-margin-bottom">
                <div className="field-label is-normal create-proposal-label">
                  <label className="label">Name:</label>
                </div>
                <div className="field-body">
                  <div className="field is-horizontal is-name-field">
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            className="input "
                            name="proposal-name"
                            type="text"
                            placeholder="Proposal Name"
                            value={proposalName}
                            onChange={handleProposalNameChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal create-proposal-label">
                      <label className="label ">Type:</label>
                    </div>
                    <div className="field-body ">
                      <div className="select field-input full-width-input">
                        <select
                          className="is-fullwidth"
                          onChange={handleProposalTypeChange}
                        >
                          {renderProposalTypes(proposalsTypes)}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal ">
                <div className="field-label is-normal create-proposal-label ">
                  <label className="label">Description:</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <textarea
                        className="field-input full-width-input description-area"
                        cols="60"
                        rows="5"
                        value={proposalDescription}
                        onChange={handleDescriptionChange}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal no-margin-bottom">
                <div className="field-label is-normal create-proposal-label">
                  <label className="label">Funding Goal:</label>
                </div>
                <div className="field-body">
                  <div className="field is-horizontal is-funding-goal-feild">
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input is-static"
                            value={proposalFundingGoalForUi || ''}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal create-proposal-label">
                      <label className="label ">Deadline:</label>
                    </div>
                    <div className="field-body ">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input is-static"
                            value={proposalFundingDeadline || ''}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal ">
                <div className="field-label is-normal create-proposal-label ">
                  <label className="label">Account:</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <AccountsSelect
                      accounts={accounts}
                      selectedAccountHandler={handleCurrentAccountChange}
                      accountListClassName="account-list-dropdown-label"
                    />
                  </div>
                </div>
              </div>

              <div className="field is-horizontal ">
                <div className="field-label is-normal create-proposal-label ">
                  <label className="label">Initial Funds:</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control has-icons-left">
                      <input
                        className="input  "
                        type="number"
                        name="proposal-initial-funds"
                        placeholder={`Minimum ${proposalInitialFundingForUi}`}
                        step="0.000000001"
                        value={`${initialFunds}`}
                        onChange={handleInitialFundChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-money-bill-wave" />
                      </span>
                    </p>
                    {isInitialFundInvalid && (
                      <p className="help is-danger">
                        This value has to be less than funding goal
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="field is-horizontal ">
                <div className="field-label is-normal create-proposal-label">
                  <label className="label ">Password:</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        name="proposal-password"
                        type="password"
                        placeholder="Please provide your account password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="field-section fee-and-button">
            <div className="field is-horizontal no-margin-bottom">
              <div className="field-label is-normal create-proposal-label ">
                <label className="label form-label field-title">Fees:</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input is-static"
                      value={createProposalFee}
                      readOnly
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal ">
              <div className="field-label is-normal create-proposal-label ">
                <label className="label">Total:</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input
                      className="input is-static"
                      value={totalValue}
                      readOnly
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal proposal-details-label">
              <div className="field-body">
                <div className="field">
                  <div className="control buttons-container ">
                    <button
                      type="button"
                      onClick={handleCancelCreateProposalClick}
                      className="button actions-button is-danger is-uppercase is-rounded is-size-6 has-text-weight-bold"
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleConfirmCreateProposalClick}
                      disabled={isCreateButtonDisabled}
                      className={`button ${isTxProcessLoading ? 'is-loading' : ''
                        } actions-button is-brand-dark has-text-white is-uppercase is-rounded is-size-6 has-text-weight-bold`}
                    >
                      create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateProposal.propTypes = {
  actions: PropTypes.object,
  accounts: PropTypes.object,
  proposalsTypes: PropTypes.array,
  proposalsOptions: PropTypes.object,
  feeEstimation: PropTypes.object,
  rawTx: PropTypes.string,
  txProcessResponse: PropTypes.string,
  txProcessError: PropTypes.object,
  sendMessageHandler: PropTypes.func,
  isTxProcessLoading: PropTypes.bool,
};

export default CreateProposal;
