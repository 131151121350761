import { request, requestConfig, governance } from '@oneledger/ons-sdk';
import {
  ONS_RPC_ENDPOINT,
  STORE_CONFIG,
  errCodeDomainNotFound,
} from '../constants/ons';

const ENV_SDK = { url: ONS_RPC_ENDPOINT, storeConfig: { ...STORE_CONFIG } };

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getSubDomains = async (payload) => {
  const { domainName } = payload;

  const byParentObj = {
    queryDomainType: requestConfig.QueryDomainType.SubDomainByName,
    domainName,
  };

  let response = [];

  try {
    const queryResult = await request.queryDomains(byParentObj, ENV_SDK);
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    const { code } = err;

    if (code === errCodeDomainNotFound) {
      throw new Error(errCodeDomainNotFound);
    }

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not fetch subdomains'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return response;
};

const getProposals = async (payload) => {
  /**
   *
   *
    const ProposalOutcome = {
      InProgress: 0x26,
      InsufficientFunds: 0x27,
      InsufficientVotes: 0x28,
      Cancelled: 0x29,
      Completed: 0x30
  }

    const QueryProposalType = {
        ByProposalID: "ByProposalID",
        ByProposalState: "ByProposalState",
        ByProposer: "ByProposer",
        ByProposalType: "ByProposalType"
    };

    const ProposalTypes = {
        Invalid: 0xEE,
        ConfigUpdate: 0x20,
        CodeChange: 0x21,
        General: 0x22
    }

    const ProposalState = {
        StateError: 0xEE,
        StateActive: 0x31,
        StatePassed: 0x32,
        StateFailed: 0x33,
        StateFinalized: 0x34,
        StateFinalizeFailed: 0x35
    }

    const ProposalStatus = {
      Funding: 0x23,
      Voting: 0x24,
      Completed: 0x25
  }

    const ProposalUIStatus = {
      Unknown: 0xEE,
      FundingInProgress: 0x01,
      VotingInProgress: 0x02,
      Approved: 0x03,
      Rejected: 0x04,
      FundingFailed: 0x05,
      Cancelled: 0x06
  }

   */

  const { type, account } = payload;

  let requestedQueryProposalType = '';
  let proposer = '';

  switch (type) {
    case 'ALL': {
      requestedQueryProposalType = requestConfig.QueryProposalType.AllProposals;
      break;
    }
    case 'MY': {
      requestedQueryProposalType = requestConfig.QueryProposalType.ByProposer;
      proposer = account;
      break;
    }
    default:
      requestedQueryProposalType = requestConfig.QueryProposalType.AllProposals;
  }

  const getProposalPayloadObj = {
    queryProposalType: requestedQueryProposalType,
    proposer,
  };

  let response = [];

  try {
    const queryResult = await request.queryProposal(
      getProposalPayloadObj,
      ENV_SDK
    );
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not fetch proposals'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return response;
};

const createProposalTx = async (payload) => {
  const {
    proposalType: givenProposalType,
    description,
    headline,
    proposer,
    initialFunding,
  } = payload;

  const myProposalType = capitalize(givenProposalType);

  const proposalPayloadObj = {
    proposalType: requestConfig.ProposalTypes[myProposalType],
    description,
    headline,
    proposer,
    initialFunding: {
      currency: 'OLT',
      value: `${initialFunding}`,
    },
  };

  let response = [];

  try {
    const queryResult = await governance.proposalCreateTxOffline(
      proposalPayloadObj,
      ENV_SDK
    );
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not get proposal create transaction'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return response;
};

const getFundProposalTx = async (payload) => {
  const { proposalID, funderAddress, fundValue } = payload;

  const proposalPayloadObj = {
    proposalID,
    funderAddress,
    fundValue: {
      currency: 'OLT',
      value: `${fundValue}`,
    },
  };

  console.log('proposalPayloadObj', proposalPayloadObj);

  let response = [];

  try {
    const queryResult = await governance.proposalFundTxOffline(
      proposalPayloadObj,
      ENV_SDK
    );
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not get fund proposal transaction'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return response;
};

const getWithdrawalProposalTx = async (payload) => {
  const { proposalID, funderAddress, withdrawalValue } = payload;

  const proposalPayloadObj = {
    proposalID,
    funder: funderAddress,
    withdrawalValue: {
      currency: 'OLT',
      value: `${withdrawalValue}`,
    },
    beneficiary: funderAddress,
  };

  const queryPayloadObj = { proposalID, funder: funderAddress };

  let response = [];

  let availableFundsForWithdraw = '';

  try {
    const queryFundsForProposalByFunderResponse = await request.queryFundsForProposalByFunder(
      queryPayloadObj,
      ENV_SDK
    );

    const {
      response: availableFundsForWithdrawResponse,
    } = queryFundsForProposalByFunderResponse;

    availableFundsForWithdraw = availableFundsForWithdrawResponse;

    const queryResult = await governance.withdrawalFundsTxOffline(
      proposalPayloadObj,
      ENV_SDK
    );
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not get withdrawal proposal transaction'
      }",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return { ...response, availableFundsForWithdraw };
};

const getCancelProposalTx = async (payload) => {
  const { proposalID, funderAddress } = payload;

  let response = [];

  const inputCancelProposal = {
    proposalID,
    proposer: funderAddress,
    reason: 'Cancelled by Proposer',
  };

  try {
    const queryResult = await governance.proposalCancelTxOffline(
      inputCancelProposal,
      ENV_SDK
    );
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not get withdrawal cancel transaction'
      }",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return response;
};

/**
 * @todo - not in use. remove when ensured it is not needed anymore
 */
const getProposalOptions = async () => {
  let proposalOptionsResponse;

  try {
    proposalOptionsResponse = await request.queryProposalOptions(ENV_SDK);
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not get proposal options'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  const {
    response: { proposalOptions, height },
  } = proposalOptionsResponse;

  return { proposalOptions, height };
};

const getProposalInfo = async (proposalType) => {
  let proposalInfoResponse;

  try {
    proposalInfoResponse = await request.calculateProposalInfo(
      proposalType,
      ENV_SDK
    );
  } catch (queryError) {
    const err = queryError.error;

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not get proposal options'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return proposalInfoResponse;
};

const getDomainInfo = async ({ domainName }) => {
  const byNameObj = {
    queryDomainType: requestConfig.QueryDomainType.ByName,
    domainName: `${domainName.trim().toLowerCase()}`,
  };

  let response;

  try {
    const queryResult = await request.queryDomains(byNameObj, ENV_SDK);
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    const { code } = err;

    if (code === errCodeDomainNotFound) {
      throw new Error(errCodeDomainNotFound);
    }

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not fetch subdomains'}",
        Details "${err.detail || 'No details are given'}"
      `
    );
  }

  return response;
};

export default {
  getSubDomains,
  getDomainInfo,
  getProposals,
  createProposalTx,
  getProposalOptions,
  getProposalInfo,
  getFundProposalTx,
  getWithdrawalProposalTx,
  getCancelProposalTx,
};
