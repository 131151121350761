export const myAppId = 'OneledgerProposalApp';
export const myAppKey = '123456';

const chromeExtensionId =
  process.env.REACT_APP_CHROME_EXTENSION_ID ||
  'afailfahdcgmdlciggncbankgeeaoklh';

export const isRunningFromElectronApp =
  process.env.REACT_APP_IS_FILE_ORIGIN === 'true' || false;

export const fileOrigin = 'file://';

export const allowedTargetOrigin = `chrome-extension://${chromeExtensionId}`;

export const myAppInfo = { appId: myAppId, appKey: myAppKey };
