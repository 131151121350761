import { requestConfig } from '@oneledger/ons-sdk';

import Mappings from '../../constants/mappings';

const { ProposalTypes } = Mappings;

// @TODO move to constants
const ProposalUIStatusLabel = {
  [requestConfig.ProposalUIStatus.Unknown]: 'Unknown',
  [requestConfig.ProposalUIStatus.FundingInProgress]: 'Funding In Progress',
  [requestConfig.ProposalUIStatus.VotingInProgress]: 'Voting In Progress',
  [requestConfig.ProposalUIStatus.Approved]: 'Approved',
  [requestConfig.ProposalUIStatus.Rejected]: 'Rejected',
  [requestConfig.ProposalUIStatus.FundingFailed]: 'Funding Failed',
  [requestConfig.ProposalUIStatus.Cancelled]: 'Cancelled',
};

export const getAccounts = ({ proposals }) => proposals.accounts || {};

const getProposalFromProposalObject = (proposalObject, myAccountsList) => {
  const { currentHeight, funds, proposal } = proposalObject;

  const {
    description,
    initialFunding,
    fundingDeadline,
    fundingGoal,
    headline,
    outCome,
    passPercent,
    proposalId,
    proposalType,
    proposer,
    status,
    statusForUI,
    votingDeadline,
  } = proposal;

  const closesIn = fundingDeadline - currentHeight;

  const isMine =
    myAccountsList &&
    myAccountsList.length &&
    myAccountsList.includes(proposer);

  const statusForUILabel =
    ProposalUIStatusLabel[requestConfig.ProposalUIStatus[statusForUI]] ||
    statusForUI;

  const typeToDisplayInUi = ProposalTypes[proposalType] || proposalType;

  return {
    name: headline,
    type: typeToDisplayInUi,
    fundsRaised: funds,
    fundsGoal: fundingGoal,
    initialFunding,
    closesIn: closesIn > 0 ? closesIn : 0,
    statusForUI,
    statusForUICode: requestConfig.ProposalUIStatus[statusForUI],
    statusForUILabel,
    proposer,
    isMine,
    id: proposalId,
    description,
  };
};

export const selectProposals = ({ proposals }) => {
  const { items, myAccountsList } = proposals;

  const proposalsList = items.map((proposalObject) =>
    getProposalFromProposalObject(proposalObject, myAccountsList)
  );

  return proposalsList;
};

export const selectCurrentProposal = ({ proposals }) => {
  const { currentProposal } = proposals;

  return currentProposal || false;
};

export const selectProposalById = (proposalsState, id) => {
  const myProposalsList = selectProposals({ proposals: proposalsState });

  const filteredList = myProposalsList.filter((proposal) => proposal.id === id);

  if (!filteredList.length) {
    return;
  }

  const [proposalToReturn] = filteredList;

  return proposalToReturn;
};

export const selectProposalTypes = ({ proposals: { proposalTypes } }) => {
  return proposalTypes;
};

export const selectProposalOptions = ({ proposals: { proposalOptions } }) => {
  return proposalOptions;
};

export const selectTxFeeEstimation = ({ proposals }) => {
  const {
    txInfo: { feeEstimation },
  } = proposals;

  return feeEstimation;
};

export const selectTxRawData = ({ proposals }) => {
  const {
    txInfo: { rawTx },
  } = proposals;

  return rawTx;
};

export const selectAvailableFundsForWithdraw = ({ proposals }) => {
  const {
    txInfo: { availableFundsForWithdraw },
  } = proposals;

  return availableFundsForWithdraw;
};

export const selectTxProposalId = ({ proposals }) => {
  const {
    txInfo: { proposalID },
  } = proposals;

  return proposalID;
};

export const selectTxProcessResponse = ({ proposals }) => {
  const { txProcessResponse } = proposals;

  return txProcessResponse;
};

export const selectTxProcessError = ({ proposals }) => {
  const { txProcessError } = proposals;

  return txProcessError;
};
