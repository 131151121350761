import React from 'react';

import Content from '../views/ProposalDetails';

const ProposalDetails = () => (
  <>
    <Content />
  </>
);

export default ProposalDetails;
