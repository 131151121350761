import { requestConfig } from '@oneledger/ons-sdk';
import * as types from '../types/proposalsTypes';

import OnsSdkApi from '../../services/onsApi';

export const setAccounts = (accounts) => ({
  type: types.SET_ACCOUNTS,
  accounts,
});

const loadStart = () => ({
  type: types.LOAD_START,
});

const loadSuccess = () => ({
  type: types.LOAD_SUCCESS,
});

const loadError = () => ({
  type: types.LOAD_ERROR,
});

export const resetProposals = () => ({
  type: types.RESET_PROPOSALS,
});

const getProposalsSuccess = (proposals) => ({
  type: types.GET_PROPOSALS_SUCCESS,
  proposals,
});

const setCreateTxInfo = (txInfo) => ({
  type: types.SET_CREATE_TX_INFO,
  txInfo,
});

const setProposalsTypes = (proposalTypes) => ({
  type: types.SET_PROPOSALS_TYPES,
  proposalTypes,
});

const setProposalsOptions = (proposalOptions) => ({
  type: types.SET_PROPOSALS_OPTIONS,
  proposalOptions,
});

export const getProposalById = (id) => ({
  type: types.GET_PROPOSAL_BY_ID,
  id,
});

export const processInfoLoadStart = () => ({
  type: types.SET_TX_PROCESS_START,
});

export const resetTxProcess = () => ({
  type: types.RESET_TX_PROCESS,
});

export const setTxProcessError = (error) => ({
  type: types.SET_TX_PROCESS_ERROR,
  error,
});

export const setTxProcessResponse = (response) => ({
  type: types.SET_TX_PROCESS_RESPONSE,
  response,
});

export const loadProposals = (account = '') => async (dispatch) => {
  dispatch(loadStart());
  dispatch(resetProposals());

  const payload = {
    type: 'ALL',
  };

  if (account) {
    payload.type = 'MY';
    payload.account = account;
  }

  try {
    const proposals = await OnsSdkApi.getProposals(payload);

    dispatch(getProposalsSuccess(proposals));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};

export const getCreateProposalTx = (payload) => async (dispatch) => {
  dispatch(loadStart());

  try {
    const proposalTxObject = await OnsSdkApi.createProposalTx(payload);

    dispatch(setCreateTxInfo(proposalTxObject));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};

export const loadProposalsTypes = () => async (dispatch) => {
  const disabledProposalTypes = ['Invalid', 'ConfigUpdate'];

  const allowedProsalTypes = Object.keys(requestConfig.ProposalTypes).filter(
    (el) => !disabledProposalTypes.includes(el)
  );

  // const allowedProsalTypes = Object.keys(requestConfig.ProposalTypes).filter(
  //   (el) => el !== 'Invalid'
  // );

  return dispatch(setProposalsTypes(allowedProsalTypes));
};

export const loadProposalsInfo = (proposalType) => async (dispatch) => {
  dispatch(loadStart());

  try {
    const proposalOptionsResponse = await OnsSdkApi.getProposalInfo(
      requestConfig.ProposalTypes[proposalType]
    );

    console.log('proposalOptionsResponse', proposalOptionsResponse);

    dispatch(setProposalsOptions(proposalOptionsResponse));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};

export const loadProposalById = (id) => async (dispatch) => {
  dispatch(loadStart());
  dispatch(resetProposals());

  const payload = {
    type: 'ALL',
  };

  try {
    const proposals = await OnsSdkApi.getProposals(payload);

    dispatch(getProposalsSuccess(proposals));
    dispatch(getProposalById(id));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};

export const setTxProcessStart = () => async (dispatch) => {
  dispatch(processInfoLoadStart());
  return dispatch(resetTxProcess());
};

export const getProposalFundTxOffline = (payload) => async (dispatch) => {
  dispatch(loadStart());

  try {
    const proposalTxObject = await OnsSdkApi.getFundProposalTx(payload);

    dispatch(setCreateTxInfo(proposalTxObject));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};

export const getProposalWithdrawalTxOffline = (payload) => async (dispatch) => {
  dispatch(loadStart());

  try {
    const proposalTxObject = await OnsSdkApi.getWithdrawalProposalTx(payload);

    dispatch(setCreateTxInfo(proposalTxObject));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};

export const getProposalCancelTxOffline = (payload) => async (dispatch) => {
  dispatch(loadStart());

  try {
    const proposalTxObject = await OnsSdkApi.getCancelProposalTx(payload);

    dispatch(setCreateTxInfo(proposalTxObject));
    return dispatch(loadSuccess());
  } catch (e) {
    return dispatch(loadError(e));
  }
};
