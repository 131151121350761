export const SET_ACCOUNTS = 'PROPOSALS/SET_ACCOUNTS';

export const LOAD_START = 'PROPOSALS/LOAD_START';
export const LOAD_SUCCESS = 'PROPOSALS/LOAD_SUCCESS';
export const LOAD_ERROR = 'PROPOSALS/LOAD_ERROR';

export const RESET_PROPOSALS = 'PROPOSALS/RESET_PROPOSALS';

export const GET_PROPOSALS_SUCCESS = 'PROPOSALS/GET_PROPOSALS_SUCCESS';

export const SET_CREATE_TX_INFO = 'PROPOSALS/SET_CREATE_TX_INFO';

export const SET_PROPOSALS_TYPES = 'PROPOSALS/SET_PROPOSALS_TYPES';
export const SET_PROPOSALS_OPTIONS = 'PROPOSALS/SET_PROPOSALS_OPTIONS';

export const GET_PROPOSAL_BY_ID = 'PROPOSALS/GET_PROPOSAL_BY_ID';

export const SET_TX_PROCESS_START = 'PROPOSALS/SET_TX_PROCESS_START';
export const RESET_TX_PROCESS = 'PROPOSALS/RESET_TX_PROCESS';
export const SET_TX_PROCESS_ERROR = 'PROPOSALS/SET_TX_PROCESS_ERROR';
export const SET_TX_PROCESS_RESPONSE = 'PROPOSALS/SET_TX_PROCESS_RESPONSE';
