/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AccountsSelect from '../../../../../common/AccountsSelect';

// eslint-disable-next-line no-unused-vars
import styles from './withdrawalModalStyle.scss';

const WithdrawalModal = (props) => {
  const {
    handleCancelWithdrawal,
    handleConfirmWithdrawal,
    getProposalWithdrawalTxOffline,
    accounts,
    currentProposal,
    feeEstimation,
    rawTx,
    availableFundsForWithdraw,
    isLoading,
  } = props;

  const { id: proposalID } = currentProposal;
  const [proposer, setProposer] = useState('');

  const [withdrawalValue, setWithdrawalValue] = useState('');
  const [password, setPassword] = useState('');
  const [transactionFee, setTransactionFee] = useState('');

  const [keyPair, setKeyPair] = useState({});

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [isWithdrawalValueInvalid, setIsWithdrawalValueInvalid] = useState(
    false
  );

  // Validation of the withdrawal value field
  useEffect(() => {
    if (availableFundsForWithdraw) {
      const [maxAvailableWithdrawal] = availableFundsForWithdraw.split(' ');
      const isValid =
        parseFloat(withdrawalValue) <= parseFloat(maxAvailableWithdrawal);

      setIsWithdrawalValueInvalid(!isValid);
    }
  }, [availableFundsForWithdraw, withdrawalValue]);

  // Set proposal fee (when is received together with rawTx and feeEstimation)
  useEffect(() => {
    if (!feeEstimation) return;

    const { value: fee, currency } = feeEstimation;

    const newFunds = parseFloat(withdrawalValue);
    const numericFee = parseFloat(fee);

    const total = newFunds + numericFee;

    if (Number.isNaN(total) || !newFunds) {
      setTransactionFee('');

      return;
    }

    setTransactionFee(`${fee} ${currency}`);
  }, [feeEstimation, withdrawalValue]);

  // Listen to all form fields change and then trigger getCreateProposalTx with updated payload
  useEffect(() => {
    const payload = {
      proposalID,
      funderAddress: proposer,
      withdrawalValue: `${withdrawalValue}`,
      password,
    };

    const payloadValues = Object.values(payload).filter(Boolean);

    const isPayloadCompleted =
      Object.keys(payload).length === payloadValues.length;

    if (isPayloadCompleted) {
      getProposalWithdrawalTxOffline(payload);
    } else {
      setTransactionFee('');
    }

    setIsConfirmButtonDisabled(!isPayloadCompleted || isWithdrawalValueInvalid);
  }, [
    getProposalWithdrawalTxOffline,
    isWithdrawalValueInvalid,
    withdrawalValue,
    proposalID,
    proposer,
    password,
  ]);

  const handleCurrentAccountChange = (account) => {
    const { address: selectedProposer, keyIndex, publicKey } = account;
    const newKeyPair = {
      keyType: 'OLT',
      keyIndex,
      publicKey,
      account: selectedProposer,
    };
    setKeyPair(newKeyPair);
    setProposer(selectedProposer);
  };

  const handlewithdrawalValueChange = (e) => {
    const newValue = e.target.value;

    setWithdrawalValue(newValue);
  };

  const handleSubmitFunding = () => {
    const myMessagePayload = {
      msg: 'sign_tx',
      other: { txInfo: { rawTx, password, keyPair } },
    };

    handleConfirmWithdrawal(myMessagePayload);
  };

  return (
    <div className="modal proposal-withdrawal-modal is-active">
      <div className="modal-background" />
      <div className="modal-content has-text-centered box">
        <p className="has-text-brand-dark is-size-4  has-text-weight-bold message-title">
          Withdrawal
        </p>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label form-label">Account</label>
          </div>
          <div className="field-body has-text-left">
            <div className="field">
              <AccountsSelect
                accounts={accounts}
                selectedAccountHandler={handleCurrentAccountChange}
                accountListClassName="account-list-dropdown-label"
              />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label form-label">Amount</label>
          </div>
          <div className="field-body">
            <div className="field has-text-left">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  placeholder="Amount"
                  value={`${withdrawalValue}`}
                  onChange={handlewithdrawalValueChange}
                  step="0.0001"
                  min="0"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-money-bill-wave" />
                </span>
              </p>
              {isWithdrawalValueInvalid && (
                <p className="help is-danger">
                  {`Must be less than your funding of "${availableFundsForWithdraw}"`}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="field is-horizontal ">
          <div className="field-label is-normal">
            <label className="label form-label">Password</label>
          </div>
          <div className="field-body ">
            <div className="field password-field">
              <div className="control ">
                <input
                  className="input "
                  type="password"
                  placeholder="Please provide your account password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal fee-feild">
          <div className="field-label is-normal fee-field-label">
            <label className="label form-label ">Fee</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control fee-control">
                <p className="fee-to-send">
                  <span>{transactionFee}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <p className="has-text-brand-dark is-size-5 is-italic message-text ">
            Would you like to proceed?
          </p>
        </div>

        <div className="field is-horizontal buttons-container">
          <div className="field-body">
            <div className="field">
              <div className="control has-text-centered">
                <button
                  type="button"
                  onClick={handleCancelWithdrawal}
                  className="button is-danger is-rounded is-uppercase has-text-white-dark actions-button has-text-weight-bold"
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <div className="field-body">
            <div className="field">
              <div className="control has-text-centered">
                <button
                  type="button"
                  onClick={handleSubmitFunding}
                  disabled={isConfirmButtonDisabled}
                  className={`${
                    isLoading ? 'is-loading' : ''
                  } button is-brand-dark is-rounded is-uppercase has-text-white-dark actions-button has-text-weight-bold`}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="modal-close is-large"
        aria-label="close"
        onClick={handleCancelWithdrawal}
      />
    </div>
  );
};

WithdrawalModal.propTypes = {
  handleConfirmWithdrawal: PropTypes.func,
  handleCancelWithdrawal: PropTypes.func,
  getProposalWithdrawalTxOffline: PropTypes.func,
  accounts: PropTypes.object,
  currentProposal: PropTypes.object,
  feeEstimation: PropTypes.object,
  rawTx: PropTypes.string,
  availableFundsForWithdraw: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default WithdrawalModal;
