import React from 'react';

import Content from '../views/CreateProposal';

const CreateProposal = () => (
  <>
    <Content />
  </>
);

export default CreateProposal;
