import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ProposalsList from '../presentation/pages/ProposalsList';
import CreateProposal from '../presentation/pages/CreateProposal';
import ProposalDetails from '../presentation/pages/ProposalDetails';
import NotFound from '../common/NotFound';

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={ProposalsList} />
      <Route path="/create" component={CreateProposal} />
      <Route path="/get/:proposalId/" component={ProposalDetails} />
      <Route render={() => <NotFound />} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
