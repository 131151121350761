/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import ProposalActionsMenu from './ProposalActionsMenu';

const handleActionsMenuDetailsClick = (history, id) => {
  history.push(`/get/${id}`);
};

const handleActionsMenuFundClick = (history, id) => {
  const location = {
    pathname: `/get/${id}`,
    state: { isFundingOn: true },
  };

  history.replace(location);
};

const handleActionsMenuWithdrawalClick = (proposal, myHandler) => {
  return myHandler(proposal, 'proposal_withdraw');
};

const handleActionsMenuCancelClick = (proposal, myHandler) => {
  return myHandler(proposal, 'proposal_cancel');
};

const proposalActions = {
  handleActionsMenuDetailsClick,
  handleActionsMenuFundClick,
  handleActionsMenuWithdrawalClick,
  handleActionsMenuCancelClick,
};

const BuildProposalActionsCell = (row, myHandler) => {
  return (
    <ProposalActionsMenu
      row={row}
      actions={proposalActions}
      myHandler={myHandler}
    />
  );
};

export default BuildProposalActionsCell;
