/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AccountsSelect from '../../../../common/AccountsSelect';

// eslint-disable-next-line no-unused-vars
import styles from './fundingModalStyle.scss';

const FundingModal = (props) => {
  const {
    handleCancelFunding,
    handleConfirmFunding,
    getProposalFundTxOffline,
    accounts,
    proposalID,
    feeEstimation,
    rawTx,
    fundsGoal,
  } = props;

  const [proposer, setProposer] = useState('');

  const [fundValue, setFundValue] = useState('');
  const [password, setPassword] = useState('');
  const [transactionFee, setTransactionFee] = useState('');

  const [keyPair, setKeyPair] = useState({});

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [isFundValueInvalid, setIsFundValueInvalid] = useState(false);

  // Validation of the fund value field
  useEffect(() => {
    if (fundsGoal) {
      const [fundingGoalForUI] = fundsGoal.split(' ');
      const isValid = parseFloat(fundValue) >= parseFloat(fundingGoalForUI);

      setIsFundValueInvalid(isValid);
    }
  }, [fundsGoal, fundValue]);

  // Set proposal fee (when is received together with rawTx and feeEstimation)
  useEffect(() => {
    if (!feeEstimation) return;

    const { value: fee, currency } = feeEstimation;

    const newFunds = parseFloat(fundValue);
    const numericFee = parseFloat(fee);

    const total = newFunds + numericFee;

    if (Number.isNaN(total) || !newFunds) {
      setTransactionFee('');

      return;
    }

    setTransactionFee(`${fee} ${currency}`);
  }, [feeEstimation, fundValue]);

  // Listen to all form fields change and then trigger getCreateProposalTx with updated payload
  useEffect(() => {
    const payload = {
      proposalID,
      funderAddress: proposer,
      fundValue: `${fundValue}`,
      password,
    };

    const payloadValues = Object.values(payload).filter(Boolean);

    const isPayloadCompleted =
      Object.keys(payload).length === payloadValues.length;

    if (isPayloadCompleted) {
      getProposalFundTxOffline(payload);
    } else {
      setTransactionFee('');
    }

    setIsConfirmButtonDisabled(!isPayloadCompleted || isFundValueInvalid);
  }, [
    getProposalFundTxOffline,
    isFundValueInvalid,
    fundValue,
    proposalID,
    proposer,
    password,
  ]);

  const handleCurrentAccountChange = (account) => {
    const { address: selectedProposer, keyIndex, publicKey } = account;
    const newKeyPair = {
      keyType: 'OLT',
      keyIndex,
      publicKey,
      account: selectedProposer,
    };
    setKeyPair(newKeyPair);
    setProposer(selectedProposer);
  };

  const handlefundValueChange = (e) => {
    const newValue = e.target.value;

    setFundValue(newValue);
  };

  const handleSubmitFunding = () => {
    const myMessagePayload = {
      msg: 'sign_tx',
      other: { txInfo: { rawTx, password, keyPair } },
    };

    handleConfirmFunding(myMessagePayload);
  };

  return (
    <div className="modal proposal-funding-modal is-active">
      <div className="modal-background" />
      <div className="modal-content has-text-centered box">
        <p className="has-text-brand-dark is-size-4  has-text-weight-bold message-title">
          Funding Proposal
        </p>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label form-label">Account</label>
          </div>
          <div className="field-body has-text-left">
            <div className="field">
              <AccountsSelect
                accounts={accounts}
                selectedAccountHandler={handleCurrentAccountChange}
                accountListClassName="account-list-dropdown-label"
              />
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label form-label">Amount</label>
          </div>
          <div className="field-body">
            <div className="field has-text-left">
              <p className="control is-expanded has-icons-left">
                <input
                  className="input"
                  type="number"
                  placeholder="Amount"
                  value={`${fundValue}`}
                  onChange={handlefundValueChange}
                  step="0.0001"
                  min="0"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-money-bill-wave" />
                </span>
              </p>
              {isFundValueInvalid && (
                <p className="help is-danger">
                  {`This value has to be less than funding goal of "${fundsGoal}"`}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="field is-horizontal ">
          <div className="field-label is-normal">
            <label className="label form-label">Password</label>
          </div>
          <div className="field-body ">
            <div className="field password-field">
              <div className="control ">
                <input
                  className="input "
                  type="password"
                  placeholder="Please provide your account password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal fee-feild">
          <div className="field-label is-normal fee-field-label">
            <label className="label form-label ">Fee</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control fee-control">
                <p className="fee-to-send">
                  <span>{transactionFee}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <p className="has-text-brand-dark is-size-5 is-italic message-text ">
            Would you like to proceed?
          </p>
        </div>

        <div className="field is-horizontal buttons-container">
          <div className="field-body">
            <div className="field">
              <div className="control has-text-centered">
                <button
                  type="button"
                  onClick={handleCancelFunding}
                  className="button is-danger is-rounded is-uppercase has-text-white-dark actions-button has-text-weight-bold"
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <div className="field-body">
            <div className="field">
              <div className="control has-text-centered">
                <button
                  type="button"
                  onClick={handleSubmitFunding}
                  disabled={isConfirmButtonDisabled}
                  className="button is-brand-dark is-rounded is-uppercase has-text-white-dark actions-button has-text-weight-bold"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="modal-close is-large"
        aria-label="close"
        onClick={handleCancelFunding}
      />
    </div>
  );
};

FundingModal.propTypes = {
  handleConfirmFunding: PropTypes.func,
  handleCancelFunding: PropTypes.func,
  getProposalFundTxOffline: PropTypes.func,
  accounts: PropTypes.object,
  proposalID: PropTypes.string,
  feeEstimation: PropTypes.object,
  rawTx: PropTypes.string,
  fundsGoal: PropTypes.string,
};

export default FundingModal;
