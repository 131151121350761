import {
  isRunningFromElectronApp,
  fileOrigin,
  myAppInfo,
  allowedTargetOrigin,
} from '../constants/ecosystem';

// it is used in 'sendMessageHandler'
const getMessageToPost = ({ message, appInfo, payload = {} }) => ({
  appId: appInfo.appId,
  appKey: appInfo.appKey,
  message,
  payload,
});

/**
   Message send handler (to be used in a useEffect hook)
   Sending the message

    useEffect(() => {
      sendMessageHandler(messageToSend, messagePayload);
    }, []);
 *
 */
export const sendMessageHandler = (message, payload) => {
  const target = isRunningFromElectronApp ? fileOrigin : allowedTargetOrigin;

  const messageToPost = getMessageToPost({
    message,
    appInfo: myAppInfo,
    payload,
  });

  // eslint-disable-next-line no-console
  console.log(`React posting to parent window "${target}"`, messageToPost);

  try {
    window.top.postMessage(messageToPost, target);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error posting to wallet!', err);
  }
};

/**
    // Message recieve handler (to be used in a useEffect hook)

    // Example of the process handler
    const myProcessHandler = (origin, data) => {
      setMyMessage(`Origin!!: "${origin}" Data: '${JSON.stringify(data)}'`);
    };

    useEffect(() => onMainAppDocumentReady(
      processHandler,
      sendHandler,
      actions
    ), []);
 *
 */
export const onMainAppDocumentReady = (
  processHandler,
  sendHandler,
  actions
) => {
  const receiveMessageHandler = (event) => {
    const { origin, data } = event;
    // eslint-disable-next-line no-console
    console.log(`React received data from "${origin}"`, data);

    const { appKey: senderAppKey, appId: senderAppId } = data;

    const { appKey: myAppKey, appId: myAppId } = myAppInfo;

    const isSenderValid = senderAppKey === myAppKey && senderAppId === myAppId;

    const isOriginValid =
      origin === allowedTargetOrigin ||
      (isRunningFromElectronApp && origin === fileOrigin);

    if (isOriginValid && isSenderValid) {
      // eslint-disable-next-line no-console
      console.log(`React processing data from "${origin}"`);
      processHandler(origin, data, actions, sendHandler);
    }
  };

  window.addEventListener('message', receiveMessageHandler);

  // clean up
  return () => window.removeEventListener('message', receiveMessageHandler);
};

export const hadshakePayload = {
  msg: 'handshake',
  other: {},
};
