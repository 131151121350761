/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import styles from './proposalsListStyle.scss';

import Loader from '../../../common/loader/loader';

import AccountsSelect from '../../../common/AccountsSelect';
import BuildProposalActionsCell from './components/BuildProposalActionsCell';
import WithdrawalModal from './components/WithdrawalModal/WithdrawalModal';
import CancelModal from './components/CancelModal/CancelModal';
import { getTxLink } from '../../../../utils';

const customStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#f3f3f3',
      backgroundColor: '#363636',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    activeSortStyle: {
      color: styles.brandLight,
      '&:focus': {
        outline: 'none',
      },
      '&:hover:not(:focus)': {
        color: styles.brandLight,
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        outline: 'none',
        color: '#f3f3f3',
      },
      '&:hover': {
        color: styles.brandLight,
      },
    },
  },
};

const optionTitles = {
  ALL_PROPOSALS: 'All Proposals',
  MY_PROPOSALS: 'My Proposals',
};

const { ALL_PROPOSALS, MY_PROPOSALS } = optionTitles;

const ProposalsList = (props) => {
  const {
    accounts,
    dataLoadingError,
    proposals,
    isLoading,
    feeEstimation,
    rawTx,
    txProcessResponse,
    txProcessError,
    isTxProcessLoading,
    availableFundsForWithdraw,
    sendMessageHandler,
    actions,
  } = props;

  const {
    loadProposals,
    getProposalWithdrawalTxOffline,
    getProposalCancelTxOffline,
    resetTxProcess,
    setTxProcessStart,
  } = actions;

  const dropDownNode = useRef();

  const history = useHistory();

  const [isOpened, setIsOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(ALL_PROPOSALS);
  const [isShowError, setIsShowError] = useState(true);

  const [currentProposal, setCurrentProposal] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);

  const [isWithdrawalModalActive, setIsWithdrawalModalActive] = useState(false);
  const [isCancelModalActive, setIsCancelModalActive] = useState(false);

  const myHandler = (givenProposal, givenProposalAction) => {
    switch (givenProposalAction) {
      case 'proposal_withdraw': {
        setIsWithdrawalModalActive(true);
        break;
      }
      case 'proposal_cancel': {
        setIsCancelModalActive(true);
        break;
      }
      default: {
        break;
      }
    }
    setCurrentProposal(givenProposal);
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Funding Raised',
      selector: 'fundsRaised',
      sortable: true,
    },
    {
      name: 'Funding Goal',
      selector: 'fundsGoal',
      center: true,
    },
    {
      name: 'Closes In',
      selector: 'closesIn',
      center: true,
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'statusForUILabel',
      center: true,
      sortable: true,
    },
    {
      name: 'Actions',
      center: true,
      cell: (row) => BuildProposalActionsCell(row, myHandler),
    },
  ];

  // If we have a 'txProcessResponse' (result of backgroud tx processing)
  // then we reset it after 5 sec
  // useEffect(() => {
  // let myTimeOut;

  // if (txProcessResponse) {
  // myTimeOut = setTimeout(() => {
  // resetTxProcess();
  // setIsShowError(false);
  // }, 5000);
  // }

  // return () => {
  // if (myTimeOut) {
  // clearTimeout(myTimeOut);
  // }
  // };
  // }, [txProcessResponse, resetTxProcess]);

  // Decide whether or not to refectch proposals
  useEffect(() => {
    // let myTimeOut;

    if (!currentAccount && !txProcessResponse) {
      // initial page load, or re-load 'all' screen and after resetting txProcessResponse
      loadProposals();
    }
    // else if (currentAccount && txProcessResponse) {
    //   // reload only after processing a tx when on'user' screen
    //   myTimeOut = setTimeout(() => {
    //     loadProposals(currentAccount.address);
    //   }, 2000);
    // }

    // return () => {
    //   if (myTimeOut) {
    //     clearTimeout(myTimeOut);
    //   }
    // };
  }, [loadProposals, txProcessResponse, currentAccount]);

  const handleDropdownAutoClosing = (e) => {
    document.removeEventListener('mousedown', handleDropdownAutoClosing);

    if (dropDownNode.current.contains(e.target)) {
      // clicked inside the dropdown. doing nothing. listener was removed
      return;
    }

    // Closing the dropdown
    setIsOpened(false);
  };

  const handleProposalsDropdownClick = (e) => {
    setIsOpened(!isOpened);
    document.addEventListener('mousedown', handleDropdownAutoClosing);
  };

  const handleSelectAllProposalsClick = () => {
    setSelectedOption(ALL_PROPOSALS);
    setCurrentAccount(null);

    document.removeEventListener('mousedown', handleDropdownAutoClosing);
    setIsOpened(!isOpened);
    actions.loadProposals();
  };

  const handleSelectMyProposalsClick = () => {
    setSelectedOption(MY_PROPOSALS);
    setCurrentAccount(null);
    document.removeEventListener('mousedown', handleDropdownAutoClosing);
    setIsOpened(!isOpened);

    actions.resetProposals();
  };

  const handleLoadAccountProposals = (account) => {
    const { address: proposer } = account;
    setCurrentAccount(account);
    actions.loadProposals(proposer);
  };

  const handleCreateProposalClick = () => {
    history.push('/create');
  };

  const handleModalCancelWithdrawal = () => {
    setIsWithdrawalModalActive(false);
    setCurrentProposal(null);
  };

  const handleModalConfirmWithdrawal = (myMessagePayload) => {
    setIsWithdrawalModalActive(false);
    setCurrentProposal(null);

    setIsShowError(true);
    setTxProcessStart();
    sendMessageHandler('Transaction to sign', myMessagePayload);
  };

  const handleModalCancelProposalCancel = () => {
    setIsCancelModalActive(false);
    setCurrentProposal(null);
  };

  const handleModalConfirmProposalCancel = (myMessagePayload) => {
    setIsCancelModalActive(false);
    setCurrentProposal(null);

    setIsShowError(true);
    setTxProcessStart();
    sendMessageHandler('Transaction to sign', myMessagePayload);
  };

  if (dataLoadingError) {
    return (
      <div>
        Error loading data. Details:
        {dataLoadingError.message}
      </div>
    );
  }

  return (
    <div className="proposals-list-main-container">
      {txProcessError && isShowError && (
        <article className="message is-danger is-small">
          <div className="message-header">
            <p>Error</p>
            <button
              onClick={() => setIsShowError(false)}
              type="button"
              className="delete is-small"
              aria-label="delete"
            />
          </div>
          <div className="message-body">
            {txProcessError.message}.{txProcessError.detail}
          </div>
        </article>
      )}

      {txProcessResponse && isShowError && (
        <article className="message is-success is-small">
          <div className="message-header">
            <p>Request has been processed</p>
            <button
              onClick={() => {
                setIsShowError(false);
                resetTxProcess();
              }}
              type="button"
              className="delete is-small"
              aria-label="delete"
            />
          </div>
          <div className="message-body">
            <a
              target="_blank"
              rel="noreferrer"
              href={getTxLink(txProcessResponse)}
            >
              Transaction URL
            </a>
          </div>
        </article>
      )}

      <p className="title">Proposals</p>

      <div className="top-menu proposals-table-title-container">
        <div className="proposals-left-title-part-container">
          <div
            ref={dropDownNode}
            className={`dropdown ${isOpened ? 'is-active' : ''}`}
          >
            <div className="dropdown-trigger">
              <button
                type="button"
                className="button dropdown-select-button proposals-owner-select proposals-select"
                aria-haspopup="true"
                aria-controls="proposals-dropdown-menu"
                onClick={handleProposalsDropdownClick}
              >
                <span className="proposals-dropdown-label">
                  {selectedOption}
                </span>
                <span className="icon is-small">
                  <i
                    className="fas fa-angle-down has-text-brand-dark"
                    aria-hidden="true"
                  />
                </span>
              </button>
            </div>
            <div className="dropdown-menu proposals-dropdown-menu" role="menu">
              <div className="dropdown-content">
                <a
                  onClick={handleSelectAllProposalsClick}
                  className="dropdown-item"
                >
                  {ALL_PROPOSALS}
                </a>
                <a
                  onClick={handleSelectMyProposalsClick}
                  className="dropdown-item"
                >
                  {MY_PROPOSALS}
                </a>
              </div>
            </div>
          </div>

          {selectedOption === MY_PROPOSALS && (
            <AccountsSelect
              accounts={accounts}
              selectedAccountHandler={handleLoadAccountProposals}
              accountListClassName="account-list-dropdown-label"
            />
          )}
        </div>

        <div className="create-a-proposal-container">
          <span
            onClick={handleCreateProposalClick}
            className="icon fa-big create-a-proposal-icon"
          >
            <i className="fas fa-plus-circle" />
          </span>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loader}>
          <Loader message="Proposals data loading..." />
        </div>
      ) : (
        <div className="domains-table-container">
          <DataTable
            title="&nbsp;"
            columns={columns}
            data={proposals}
            customStyles={customStyles}
            striped
            dense
            noHeader
            pagination
            defaultSortField="closesIn"
            defaultSortAsc={false}
            className=" table is-bordered is-hoverable show-overflow"
          />
        </div>
      )}

      {isWithdrawalModalActive && currentProposal && (
        <WithdrawalModal
          handleCancelWithdrawal={handleModalCancelWithdrawal}
          handleConfirmWithdrawal={handleModalConfirmWithdrawal}
          getProposalWithdrawalTxOffline={getProposalWithdrawalTxOffline}
          accounts={accounts}
          currentProposal={currentProposal}
          feeEstimation={feeEstimation}
          rawTx={rawTx}
          availableFundsForWithdraw={availableFundsForWithdraw}
          isLoading={isTxProcessLoading || isLoading}
        />
      )}
      {isCancelModalActive && currentProposal && (
        <CancelModal
          handleCancelAction={handleModalCancelProposalCancel}
          handleConfirmAction={handleModalConfirmProposalCancel}
          getProposalTxOffline={getProposalCancelTxOffline}
          accounts={accounts}
          currentProposal={currentProposal}
          feeEstimation={feeEstimation}
          rawTx={rawTx}
          isLoading={isTxProcessLoading || isLoading}
        />
      )}
    </div>
  );
};

ProposalsList.propTypes = {
  actions: PropTypes.object,
  accounts: PropTypes.object,
  dataLoadingError: PropTypes.object,
  proposals: PropTypes.array,
  isLoading: PropTypes.bool,
  feeEstimation: PropTypes.object,
  availableFundsForWithdraw: PropTypes.string,
  rawTx: PropTypes.string,
  txProcessResponse: PropTypes.string,
  txProcessError: PropTypes.object,
  isTxProcessLoading: PropTypes.bool,
  sendMessageHandler: PropTypes.func,
};

export default ProposalsList;
