import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProposalsList from './ProposalsList';

import {
  loadProposals,
  resetProposals,
  getProposalWithdrawalTxOffline,
  setAccounts,
  setTxProcessStart,
  setTxProcessError,
  setTxProcessResponse,
  resetTxProcess,
  getProposalCancelTxOffline,
} from '../../../../store/actions/proposalsActions';

import {
  getAccounts,
  selectProposals,
  selectTxFeeEstimation,
  selectTxRawData,
  selectTxProcessError,
  selectTxProcessResponse,
  selectAvailableFundsForWithdraw,
} from '../../../../store/selectors/proposalsSelectors';

import withMessaging from '../../../common/withMessaging';

export default connect(
  (state) => ({
    accounts: getAccounts(state),
    dataLoadingError: state.proposals.error,
    proposals: selectProposals(state),
    isLoading: state.proposals.isLoading,
    feeEstimation: selectTxFeeEstimation(state),
    rawTx: selectTxRawData(state),
    txProcessResponse: selectTxProcessResponse(state),
    txProcessError: selectTxProcessError(state),
    isTxProcessLoading: state.proposals.isTxProcessLoading,
    availableFundsForWithdraw: selectAvailableFundsForWithdraw(state),
  }),
  (dispatch) => ({
    actions: {
      ...bindActionCreators(
        {
          setAccounts,
          loadProposals,
          resetProposals,
          getProposalWithdrawalTxOffline,
          getProposalCancelTxOffline,
          setTxProcessError,
          setTxProcessStart,
          setTxProcessResponse,
          resetTxProcess,
        },
        dispatch
      ),
    },
  })
)(withMessaging(ProposalsList));
