import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  onMainAppDocumentReady,
  sendMessageHandler,
  hadshakePayload,
} from '../../services/ecosystemAppsService';

import { processMessageHandler } from '../../services/ecosystemAppsHandlers';

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const withMessaging = (WrappedComponent) => {
  const WithMessaging = (props = {}) => {
    const { actions } = props;

    const [messagePayload] = useState(hadshakePayload);

    // Sending the initial message with a connection request - upon initial page load
    useEffect(() => sendMessageHandler('Connection request', messagePayload), [
      messagePayload,
    ]);

    // Initialize the message receiving handler - upon initial page load
    useEffect(
      () =>
        onMainAppDocumentReady(
          processMessageHandler,
          sendMessageHandler,
          actions
        ),
      [actions]
    );

    return (
      <WrappedComponent {...props} sendMessageHandler={sendMessageHandler} />
    );
  };

  WithMessaging.displayName = `WithMessaging(${getDisplayName(
    WrappedComponent
  )})`;

  WithMessaging.propTypes = {
    actions: PropTypes.object,
  };

  return WithMessaging;
};

export default withMessaging;
