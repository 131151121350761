import React from 'react';

import Content from '../views/ProposalsList';

const ProposalsList = () => (
  <>
    <Content />
  </>
);

export default ProposalsList;
