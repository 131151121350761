/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Ops..</h1>
        <h2 className="subtitle">
          The page you were looking for was not found.
        </h2>

        <button type="button" onClick={() => history.push('/')}>
          Go Home
        </button>
      </div>
    </section>
  );
};

export default NotFound;
