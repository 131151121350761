/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import styles from './proposalDetailsStyle.scss';

import Loader from '../../../common/loader/loader';
import NotFound from '../../../common/NotFound';
import { getTxLink } from '../../../../utils';

import FundingModal from './components/FundingModal';

const ProposalDetails = (props) => {
  const {
    accounts,
    proposal,
    isProposalsLoading,
    feeEstimation,
    rawTx,
    txProcessResponse,
    txProcessError,
    isTxProcessLoading,
    sendMessageHandler,
    actions,
  } = props;

  const {
    loadProposalById,
    resetTxProcess,
    setTxProcessStart,
    getProposalFundTxOffline,
  } = actions;

  const history = useHistory();

  const { state: locationState = {} } = useLocation();

  const { isFundingOn } = locationState;

  const [isFundingModalActive, setIsFundingModalActive] = useState(isFundingOn);

  const [isShowError, setIsShowError] = useState(true);

  const { proposalId } = useParams();

  // Load a requested proposal by its id
  useEffect(() => {
    loadProposalById(proposalId);
  }, [proposalId, loadProposalById]);

  // Reset any transactions what is processed - upon initial page load
  useEffect(() => {
    resetTxProcess();
  }, [resetTxProcess]);

  // Re-fetch a proposal after receiving a response from the server and hide the tx info window
  useEffect(() => {
    let myTimeOut;

    if (txProcessResponse && isShowError) {
      myTimeOut = setTimeout(() => {
        // resetTxProcess();
        loadProposalById(proposalId);
        // setIsShowError(false);
      }, 5000);
    }

    return () => {
      if (myTimeOut) {
        clearTimeout(myTimeOut);
      }
    };
  }, [
    txProcessResponse,
    proposalId,
    loadProposalById,
    // resetTxProcess,
    isShowError,
  ]);

  const handleCancelFundingClick = () => {
    history.push('/');
  };

  const handleConfirmFundingClick = () => {
    setIsFundingModalActive(true);
  };

  const handleModalCancelFunding = () => {
    setIsFundingModalActive(false);
  };

  const handleModalConfirmFunding = (myMessagePayload) => {
    setIsFundingModalActive(false);
    setIsShowError(true);
    setTxProcessStart();
    sendMessageHandler('Transaction to sign', myMessagePayload);
  };

  if (proposal === false) {
    return <NotFound />;
  }

  const {
    closesIn,
    fundsGoal,
    fundsRaised,
    id,
    name,
    type,
    description,
  } = proposal;

  const isFundingButtonDisabled = !closesIn;

  return (
    <div className="proposal-details-main-container">
      <div className="proposal-details-container">
        <div className="proposal-form">
          {txProcessError && isShowError && (
            <article className="message is-danger is-small">
              <div className="message-header">
                <p>Error</p>
                <button
                  onClick={() => setIsShowError(false)}
                  type="button"
                  className="delete is-small"
                  aria-label="delete"
                />
              </div>
              <div className="message-body">
                {txProcessError.message}.{txProcessError.detail}
              </div>
            </article>
          )}

          {txProcessResponse && isShowError && (
            <article className="message is-success is-small">
              <div className="message-header">
                <p>Request has been processed</p>
                <button
                  onClick={() => {
                    setIsShowError(false);
                    resetTxProcess();
                  }}
                  type="button"
                  className="delete is-small"
                  aria-label="delete"
                />
              </div>
              <div className="message-body">
                {' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={getTxLink(txProcessResponse)}
                >
                  Transaction URL
                </a>
              </div>
            </article>
          )}

          <p className="title">Proposal Details</p>

          {isProposalsLoading ? (
            <div className={styles.loader}>
              <Loader message="Proposals data loading..." />
            </div>
          ) : (
            <>
              <div className="field is-horizontal">
                <div className="field-label is-normal proposal-details-label">
                  <label className="label">Name:</label>
                </div>
                <div className="field-body">
                  <div
                    className="field is-horizontal "
                    style={{ width: '50%' }}
                  >
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input is-static"
                            value={name || ''}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal" style={{ width: '50%' }}>
                    <div className="field-label is-normal proposal-details-label">
                      <label className="label ">Type:</label>
                    </div>
                    <div className="field-body ">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input is-static"
                            value={type || ''}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal proposal-details-label">
                  <label className="label">Funding Goal:</label>
                </div>
                <div className="field-body">
                  <div
                    className="field is-horizontal "
                    style={{ width: '50%' }}
                  >
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input is-static"
                            value={fundsGoal || ''}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal" style={{ width: '50%' }}>
                    <div className="field-label is-normal proposal-details-label">
                      <label className="label ">Closes In:</label>
                    </div>
                    <div className="field-body ">
                      <div className="field">
                        <p className="control">
                          <input
                            className="input is-static"
                            value={closesIn || '0'}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal proposal-details-label">
                  <label className="label">Funding Raised:</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <input
                        className="input is-static"
                        value={fundsRaised || ''}
                        readOnly
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal proposal-details-label">
                  <label className="label">Decription:</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                      <textarea
                        className="field-input full-width-input description-area"
                        cols="60"
                        rows="8"
                        readOnly
                        value={description}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="field is-horizontal buttons-container-field">
            <div className="field-body">
              <div className="field">
                <div className="control buttons-container ">
                  <button
                    type="button"
                    onClick={handleCancelFundingClick}
                    className="button actions-button is-danger is-uppercase is-rounded is-size-6 has-text-weight-bold"
                  >
                    cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleConfirmFundingClick}
                    disabled={isFundingButtonDisabled}
                    className={` ${
                      isTxProcessLoading ? 'is-loading' : ''
                    } button actions-button is-brand-dark has-text-white is-uppercase is-rounded is-size-6 has-text-weight-bold`}
                  >
                    fund
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isFundingModalActive && (
        <FundingModal
          handleCancelFunding={handleModalCancelFunding}
          handleConfirmFunding={handleModalConfirmFunding}
          getProposalFundTxOffline={getProposalFundTxOffline}
          accounts={accounts}
          proposalID={id}
          feeEstimation={feeEstimation}
          rawTx={rawTx}
          fundsGoal={fundsGoal}
        />
      )}
    </div>
  );
};

ProposalDetails.propTypes = {
  accounts: PropTypes.object,
  proposal: PropTypes.object,
  isProposalsLoading: PropTypes.bool,
  feeEstimation: PropTypes.object,
  rawTx: PropTypes.string,
  txProcessResponse: PropTypes.string,
  txProcessError: PropTypes.object,
  isTxProcessLoading: PropTypes.bool,
  sendMessageHandler: PropTypes.func,
  actions: PropTypes.object,
};

export default ProposalDetails;
