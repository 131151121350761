import initialState from '../initialStates/proposalsInitialState';
import * as types from '../types/proposalsTypes';
import { selectProposalById } from '../selectors/proposalsSelectors';

const getmyAccountsList = (accounts) => {
  if (!accounts) return [];
  const { OLT: myAccounts = [] } = accounts;

  return myAccounts.map((accountElement) => {
    const { address } = accountElement;
    return address;
  });
};

const proposalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCOUNTS:
      return {
        ...state,
        accounts: { ...action.accounts },
        myAccountsList: getmyAccountsList(action.accounts),
      };
    case types.LOAD_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case types.LOAD_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case types.RESET_PROPOSALS:
      return {
        ...state,
        items: [],
      };

    case types.GET_PROPOSALS_SUCCESS:
      return {
        ...state,
        items: [...action.proposals],
      };

    case types.SET_CREATE_TX_INFO:
      return {
        ...state,
        txInfo: { ...action.txInfo },
      };

    case types.SET_PROPOSALS_TYPES:
      return {
        ...state,
        proposalTypes: [...action.proposalTypes],
      };
    case types.SET_PROPOSALS_OPTIONS:
      return {
        ...state,
        proposalOptions: { ...action.proposalOptions },
      };

    case types.GET_PROPOSAL_BY_ID:
      return {
        ...state,
        currentProposal: selectProposalById(state, action.id),
      };

    case types.SET_TX_PROCESS_START:
      return {
        ...state,
        isTxProcessLoading: true,
      };
    case types.RESET_TX_PROCESS:
      return {
        ...state,
        txProcessResponse: initialState.txProcessResponse,
        txProcessError: initialState.txProcessError,
      };
    case types.SET_TX_PROCESS_ERROR:
      return {
        ...state,
        txProcessError: action.error,
        txProcessResponse: initialState.txProcessResponse,
        isTxProcessLoading: false,
      };
    case types.SET_TX_PROCESS_RESPONSE:
      return {
        ...state,
        txProcessError: initialState.txProcessError,
        txProcessResponse: JSON.stringify(action.response),
        isTxProcessLoading: false,
      };

    default:
      return state;
  }
};

export default proposalsReducer;
