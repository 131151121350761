const DEVNET = 'devnet';
const CHRONOS = 'chronos';
const KAINOS = 'kainos';
const KRATOS = 'kratos';

const DEVNET_URL =
  process.env.REACT_APP_DEVNET_URL || 'http://devnet2.oneledger.network';
const CHRONOS_URL =
  process.env.CHRONOS_URL || 'https://chronos.oneledger.network';
const KAINOS_URL =
  process.env.REACT_APP_KAINOS_URL || 'https://kainos.oneledger.network';

const KRATOS_URL =
  process.env.REACT_APP_KRATOS_URL || 'https://kratos.oneledger.network';

// const KRATOS_URL =
//   process.env.REACT_APP_KRATOS_URL || 'https://kratos-test.oneledger.network';

export const NETWORKS = {
  DEVNET,
  CHRONOS,
  KAINOS,
  DEVNET_URL,
  CHRONOS_URL,
  KAINOS_URL,
  KRATOS_URL,
};

export const RPC_URLS = {
  [KRATOS]: 'https://fullnode-sdk.kratos.oneledger.network/jsonrpc',
  // [KRATOS]: 'https://fullnode-sdk-test.kratos.oneledger.network/jsonrpc', // update for TEST
  [KAINOS]: 'https://fullnode-sdk.kainos.oneledger.network/jsonrpc',
  [CHRONOS]: 'https://fullnode-sdk.chronos.oneledger.network/jsonrpc',
  [DEVNET]: 'http://fullnode-sdk.devnet2.oneledger.network/jsonrpc',
};

export const EXPLORER_URLS = {
  // [KRATOS]: 'https://kratos-test.oneledger.network', // test
  [KRATOS]: 'https://kratos.oneledger.network',
  [KAINOS]: 'https://kainos.oneledger.network',
  [CHRONOS]: 'https://chronos.oneledger.network',
  [DEVNET]: 'http://devnet2.oneledger.network',
};

export const defaultNetworkName = process.env.REACT_APP_NETWORK_NAME || CHRONOS;

const OLT_REMOTE_URL = RPC_URLS[defaultNetworkName] || RPC_URLS[CHRONOS];

// Ons SDK RPC url
export const ONS_RPC_ENDPOINT = OLT_REMOTE_URL;

// OneLedger explorer ui url
export const EXPLORER_URL =
  EXPLORER_URLS[defaultNetworkName] || EXPLORER_URLS[CHRONOS];

// ONS Store config
export const STORE_CONFIG = {
  platform: 'browser',
  storeLocation: __dirname,
};

export const errCodeDomainNotFound = -100502;
