const ConfigUpdate = 'Config Update';
const CodeChange = 'Code Change';
const General = 'General';

const ProposalTypes = {
  ConfigUpdate,
  CodeChange,
  General,
};

export default {
  ProposalTypes,
};
