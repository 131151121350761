const accounts = {
  OLT: [],
};

const proposals = {
  accounts,
  isLoading: false,
  myAccountsList: [],
  error: null,
  items: [],
  proposalOptions: {},
  proposalTypes: [],
  txInfo: {},
  currentProposal: {},
  txProcessError: null,
  txProcessResponse: null,
  isTxProcessLoading: false,
};

export default proposals;
